import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VThemeProvider } from 'vuetify/lib/components/VThemeProvider';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VThemeProvider,{attrs:{"dark":""}},[_c('base-section',{staticClass:"grey darken-4",attrs:{"id":"info-alt"}},[_c(VResponsive,{staticClass:"mx-auto",attrs:{"max-width":"1400"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('base-info')],1),_c(VCol,{attrs:{"cols":"12","md":"4"}}),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('base-info-card',{attrs:{"title":"Contact"}}),_c('base-business-contact',{attrs:{"dense":""}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }